import styled from "styled-components";
import ThemeStyle from "../../../../styles/ThemeStyle";

export const Label = styled.label`
  position: relative;
  display: flex;
  padding-bottom: 4rem;
  margin-bottom: 2rem;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  background-color: ${ThemeStyle.colors.background.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 2px;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background-color: ${ThemeStyle.colors.text.darkGrey};
    transition: ${ThemeStyle.transition};
    transition-property: width, height;
  }
  &:checked {
    &:before {
      width: 100%;
      height: 100%;
    }
  }
`;

export const LabelText = styled.span`
  font-size: 1.2rem;
  padding-left: 1rem;
  padding-top: 0;
  font-weight: ${ThemeStyle.fontWeight.light};
  cursor: pointer;
  color: ${ThemeStyle.colors.text.grey};
  a {
    display: inline-block;
    font-weight: ${ThemeStyle.fontWeight.bold};
    transition: ${ThemeStyle.transition};
    transition-property: color;
    &:hover {
      color: ${ThemeStyle.colors.text.darkGrey};
    }
  }
`;
