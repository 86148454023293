import React from "react";

export const LinkedInIcon = () => {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.22111 23.9723V8.02489H0.647735V23.9723H6.22111ZM3.43515 5.84623C5.37868 5.84623 6.58844 4.62166 6.58844 3.09134C6.55223 1.52652 5.37875 0.335938 3.47203 0.335938C1.5656 0.335938 0.318909 1.52655 0.318909 3.09134C0.318909 4.62173 1.52837 5.84623 3.39876 5.84623H3.43515ZM9.30596 23.9723H14.8793V15.0665C14.8793 14.5899 14.9156 14.1137 15.0627 13.773C15.4656 12.8207 16.3827 11.8344 17.9223 11.8344C19.9391 11.8344 20.7459 13.2969 20.7459 15.4407V23.9722H26.3189V14.8281C26.3189 9.92967 23.5693 7.65049 19.9024 7.65049C16.8957 7.65049 15.5756 9.24883 14.8423 10.3374H14.8795V8.02456H9.30608C9.37923 9.52097 9.30608 23.972 9.30608 23.972L9.30596 23.9723Z" />
    </svg>
  );
};

export default LinkedInIcon;
