import React from "react";
import * as Styled from "./Social.styled";
import { FacebookIcon } from "./FacebookIcon";
import { InstagramIcon } from "./InstagramIcon";
import { LinkedInIcon } from "./LinkedInIcon";

const Social = () => {
  return (
    <Styled.Social>
      <Styled.Content>
        <Styled.Subtitle>Obserwuj nas i bądź na bieżąco.</Styled.Subtitle>
        <Styled.IconBox>
          <Styled.Icon
            href="https://www.facebook.com/capemorris.agency"
            target={"_blank"}
          >
            <FacebookIcon />
          </Styled.Icon>
          <Styled.Icon
            href="https://www.instagram.com/capemorris.agency/"
            target={"_blank"}
          >
            <InstagramIcon />
          </Styled.Icon>
          <Styled.Icon
            href="https://pl.linkedin.com/company/cape-morris"
            target={"_blank"}
          >
            <LinkedInIcon />
          </Styled.Icon>
        </Styled.IconBox>
      </Styled.Content>
    </Styled.Social>
  );
};

export default Social;
