import React, { FunctionComponent } from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as Styled from "./TabletSlider.styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

type Props = {};

const TabletSlider: FunctionComponent<Props> = (props) => {
  return (
    <Styled.Content>
      <StaticImage
        class={"tablet"}
        src="../../../../images/form-tablet.png"
        alt="Zdjęcie przedstawiające tablet wyświetlający e-booka"
        placeholder="blurred"
        quality={100}
        layout="constrained"
        objectFit={"contain"}
      />
      <Swiper
        loop={true}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <Styled.Text>
            44% osób w&nbsp;wieku 65+{" "}
            <strong>posiada konto w&nbsp;SoME.</strong>
          </Styled.Text>
        </SwiperSlide>
        <SwiperSlide>
          <Styled.Text>
            Jeśli obecny trend się utrzyma, to według GUS&#x2011;u{" "}
            <strong>do 2050 roku 45% Polaków będzie po 50&#x2011;tce.</strong>
          </Styled.Text>
        </SwiperSlide>
        <SwiperSlide>
          <Styled.Text>
            94% igreków wyraża{" "}
            <strong>opinie o&nbsp;markach i&nbsp;zakupach.</strong>
          </Styled.Text>
        </SwiperSlide>
      </Swiper>
    </Styled.Content>
  );
};

export default TabletSlider;
