import styled, { css } from "styled-components";
import ThemeStyle from "../../../../styles/ThemeStyle";
import { mediaDown } from "../../../../styles/Mixins";
import LoaderGif from "../../../../images/spinner.gif";

export const FormElement = styled.form`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8rem 0;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      padding: 4rem 0;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 4rem 0 0;
    `
  )};
`;

export const Title = styled.h3`
  font-weight: ${ThemeStyle.fontWeight.regular};
  font-size: 1.8rem;
  line-height: 1.8;
  margin-bottom: 4rem;
  strong {
    font-weight: ${ThemeStyle.fontWeight.ultra};
  }
`;

export const SuccessTitle = styled.p`
  ${Title};
  display: flex;
  align-items: center;
  font-size: 2rem;
  strong {
    display: contents;
    font-weight: ${ThemeStyle.fontWeight.bold};
    color: ${ThemeStyle.colors.blue};
  }
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.8rem;
      padding-top: 3rem;
      border-top: 1px solid ${ThemeStyle.colors.background.darkGrey};
    `
  )};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  padding-bottom: 3rem;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      width: 100%;
    `
  )};
`;

export const Input = styled.input`
  font-family: ${ThemeStyle.fonts.main};
  font-weight: ${ThemeStyle.fontWeight.regular};
  background-color: ${ThemeStyle.colors.background.grey};
  font-size: 1.4rem;
  color: ${ThemeStyle.colors.text.darkGrey};
  padding: 2rem;
  width: 100%;
  height: 100%;
  min-width: 48rem;

  &::placeholder {
    font-family: ${ThemeStyle.fonts.main};
    font-weight: 300;
    color: ${ThemeStyle.colors.text.darkGrey};
  }
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      min-width: unset;
    `
  )};
`;

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-bottom: 6rem;
  width: 100%;
`;

export const Loader = styled.div`
  background-image: url(${LoaderGif});
  background-size: contain;
  width: 5rem;
  height: 5rem;
  animation: fade 0.3s linear;
  @keyframes fade {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Message = styled.p`
  position: absolute;
  left: 0;
  bottom: 1rem;
  font-size: 1.4rem;
  width: 100%;
  font-weight: ${ThemeStyle.fontWeight.regular};
`;

export const SuccessMessage = styled(Message)`
  color: ${ThemeStyle.colors.text.darkGrey};
`;

export const ErrorMessage = styled(Message)`
  color: ${ThemeStyle.colors.red};
`;
