import React, { FunctionComponent, useState, useRef } from "react";
import * as Styled from "./Form.styled";
import * as CheckboxStyled from "./Checkbox.styled";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../../../components/Button/Button";
import axios from "axios";
import Recaptcha from "./Recaptcha/Recaptcha";

type Props = {};

type FormData = {
  additionalFields: {
    name: string;
    source: string;
  };
  email: string;
  source: string;
  regulationsAccepted: boolean;
  recaptcha: string;
};

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const recaptchaSiteKey: string = process.env.GATSBY_RECAPTCHA
  ? process.env.GATSBY_RECAPTCHA
  : "";

const Form: FunctionComponent<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isEmailOnList, setIsEmailOnList] = useState<boolean>(false);
  const [reHash, setReHash] = React.useState<undefined | string | number>();
  const formRef = useRef(null);
  const successMessage = useRef(null);
  const methods = useForm<FormData>({
    defaultValues: {
      regulationsAccepted: false,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const pushGtagEvent = React.useCallback(() => {
    if (!window) {
      return;
    }
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "signup",
      event_category: "form",
      event_label: "sign",
      value: "signup",
    });
  }, []);

  const scrollToSuccessMessage = () => {
    if (successMessage) {
      // @ts-ignore
      successMessage.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const submitData = React.useCallback((data: FormData) => {
    setIsError(false);
    setIsSuccess(false);
    setIsEmailOnList(false);
    axios
      .post(`${process.env.GATSBY_FORM_API}/contacts`, data)
      .then((response) => {
        setIsSuccess(true);
        setIsLoading(false);
        setIsEmailOnList(false);
        setIsError(false);
        scrollToSuccessMessage();
        pushGtagEvent();
        reset();
      })
      .catch((error) => {
        if (error.response!.status === 400) {
          setIsError(false);
          setIsEmailOnList(true);
          setIsLoading(false);
          return;
        }
        setIsEmailOnList(false);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  const submitForm = React.useCallback((data: FormData) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);
    setIsEmailOnList(false);
    setReHash(Math.random());
  }, []);

  const preSubmitData = React.useCallback(() => {
    methods.handleSubmit(submitData, () => {
      setIsError(false);
      setIsEmailOnList(false);
      setIsLoading(false);
    })();
  }, [formRef]);

  return (
    <>
      {isSuccess && (
        <Styled.SuccessTitle ref={successMessage}>
          <strong>Formularz został pomyślnie wysłany!</strong>
          <br />
          <br />
          Mamy nadzieję, że lektura przyniesie Ci wiele inspirujących
          przemyśleń.
        </Styled.SuccessTitle>
      )}
      {!isSuccess && (
        <FormProvider {...methods}>
          <Styled.FormElement onSubmit={handleSubmit(submitForm)} ref={formRef}>
            <Styled.Title>
              <strong>Podaj swój adres e-mail </strong>
              <br />
              i&nbsp;otrzymaj e-book
            </Styled.Title>
            <Styled.InputWrapper>
              <Styled.Input
                {...register("additionalFields.name", {
                  required: true,
                })}
                type={"text"}
                placeholder={"Imię i nazwisko*"}
              />
              {errors.additionalFields && (
                <Styled.ErrorMessage>
                  To pole jest wymagane.
                </Styled.ErrorMessage>
              )}
            </Styled.InputWrapper>
            <Styled.InputWrapper>
              <Styled.Input
                {...register("email", {
                  required: true,
                  pattern: emailPattern,
                })}
                type={"email"}
                placeholder={"Służbowy e-mail*"}
              />
              {errors.email && (
                <Styled.ErrorMessage>
                  Wprowadź prawidłowy adres e-mail.
                </Styled.ErrorMessage>
              )}
            </Styled.InputWrapper>
            <input type="hidden" value="ebook" {...register("source")} />
            <input
              type="hidden"
              value="landingpage"
              {...register("additionalFields.source")}
            />
            <CheckboxStyled.Label>
              <CheckboxStyled.Input
                type="checkbox"
                {...register("regulationsAccepted", { required: true })}
              />
              <CheckboxStyled.LabelText>
                *Zapisując się do pobrania e-booka, zgadzasz się na otrzymywanie
                od Cape Morris PL Sp. z&nbsp;o.o. z&nbsp;siedzibą
                w&nbsp;Warszawie, przy ul. Niedźwiedziej 29A (Administrator)
                spersonalizowanych treści marketingowych dotyczących
                Administratora oraz jego partnerów biznesowych. Twój adres
                e‑mail Administrator będzie przetwarzać w&nbsp;celu kierowania
                do Ciebie treści marketingowych. Zgodę możesz wycofać
                w&nbsp;każdym czasie, co nie wpłynie na zgodność z&nbsp;prawem
                przetwarzania dokonanego przed jej wycofaniem. Więcej informacji
                o&nbsp;przetwarzaniu Twoich danych osobowych, w&nbsp;tym
                o&nbsp;przysługujących Ci uprawnieniach, znajduje się w&nbsp;
                <a href="/polityka-prywatnosci.pdf" target={"_blank"}>
                  Polityce prywatności.
                </a>
              </CheckboxStyled.LabelText>
              {errors.regulationsAccepted && (
                <Styled.ErrorMessage>
                  Zgoda jest obowiązkowa.
                </Styled.ErrorMessage>
              )}
            </CheckboxStyled.Label>
            <input
              type={"hidden"}
              {...register("recaptcha", { required: true })}
            />
            <Recaptcha executionHash={reHash} onSuccess={preSubmitData} />
            <Styled.ButtonWrapper>
              <Button
                text={"Pobierz e-book"}
                color={"black"}
                type={"button"}
                onClick={submitForm}
                disabled={isLoading}
              />
              {isLoading && <Styled.Loader />}
              {isEmailOnList && (
                <Styled.ErrorMessage>
                  Na ten adres e-mail wysłaliśmy już e-booka. Szukaj go
                  w&nbsp;swojej skrzynce odbiorczej!
                </Styled.ErrorMessage>
              )}
              {isError && (
                <Styled.ErrorMessage>
                  Wysłanie formularza nie powiodło się. Spróbuj ponownie
                  później.
                </Styled.ErrorMessage>
              )}
            </Styled.ButtonWrapper>
          </Styled.FormElement>
        </FormProvider>
      )}
    </>
  );
};

export default Form;
