import styled, { css } from "styled-components";
import ThemeStyle from "../../../../styles/ThemeStyle";
import { mediaDown } from "../../../../styles/Mixins";

export const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tablet {
    height: 100%;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    ${mediaDown(
      ThemeStyle.breakpoints.md,
      css`
        max-height: 50vh;
      `
    )};
  }
  img {
    height: 100%;
    width: 100%;
  }

  .swiper {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 40%;
    transform: translate(-27%, -45%);
    overflow: hidden;
    ${mediaDown(
      ThemeStyle.breakpoints.md,
      css`
        max-width: 35%;
      `
    )};
    ${mediaDown(
      ThemeStyle.breakpoints.md,
      css`
        max-width: 45%;
      `
    )};
    ${mediaDown(
      ThemeStyle.breakpoints.sm,
      css`
        max-width: 44%;
        transform: translate(-28%, -45%);
      `
    )};
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: ${ThemeStyle.fontWeight.light};
  cursor: pointer;
  strong {
    font-weight: ${ThemeStyle.fontWeight.bold};
  }
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      font-size: 1.6rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.3rem;
    `
  )};
`;
