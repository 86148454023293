import styled, { css } from "styled-components";
import ThemeStyle from "../../styles/ThemeStyle";
import { mediaDown } from "../../styles/Mixins";
import { Container } from "../../styles/BasicStyles";

export const FormContent = styled.section`
  width: 100%;
  background-color: ${ThemeStyle.colors.background.white};
  padding: 12rem 0 0;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      padding: 6rem 0 0;
    `
  )}
`;

export const Content = styled(Container)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-self: center;
`;

export const Subtitle = styled.p`
  font-weight: ${ThemeStyle.fontWeight.ultra};
  font-size: 2rem;
  text-align: center;
  margin-bottom: 4rem;
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      margin-bottom: 2rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 1.6rem;
      br {
        display: none;
      }
    `
  )}
`;

export const Title = styled.h2`
  font-size: 5rem;
  text-align: center;
  font-weight: ${ThemeStyle.fontWeight.light};
  max-width: 90rem;
  align-self: center;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      font-size: 4rem;
    `
  )}
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      font-size: 2.8rem;
    `
  )}
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 6rem;
  padding: 4rem 0 0;
  ${mediaDown(
    ThemeStyle.breakpoints.lg,
    css`
      grid-template-columns: 1fr 2fr;
      gap: 2rem;
    `
  )};
  ${mediaDown(
    ThemeStyle.breakpoints.md,
    css`
      grid-template-columns: 1fr;
    `
  )};
`;
