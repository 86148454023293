import React, { FunctionComponent } from "react";
import * as Styled from "./FormSection.styled";
import Form from "./components/Form/Form";
import TabletSlider from "./components/TabletSlider/TabletSlider";

type Props = {};

const FormSection: FunctionComponent<Props> = () => {
  return (
    <Styled.FormContent id={"form"}>
      <Styled.Content>
        <Styled.Subtitle>
          Dowiedz się więcej o swojej grupie docelowej.
          <br /> Pobierz e-book.
        </Styled.Subtitle>
        <Styled.Title>
          Wypełnij formularz, żeby odebrać 40 stron marketingowej wiedzy.
        </Styled.Title>
        <Styled.Wrapper>
          <Form />
          <TabletSlider />
        </Styled.Wrapper>
      </Styled.Content>
    </Styled.FormContent>
  );
};

export default FormSection;
