import React from "react";

export const FacebookIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.136963 13.2269C0.136963 19.69 4.83105 25.0645 10.9703 26.1543V16.765H7.7203V13.1543H10.9703V10.265C10.9703 7.01505 13.0644 5.21021 16.0262 5.21021C16.9644 5.21021 17.9762 5.3543 18.9144 5.49838V8.82096H17.2536C15.6644 8.82096 15.3036 9.61505 15.3036 10.6269V13.1543H18.7703L18.1929 16.765H15.3036V26.1543C21.4429 25.0645 26.137 19.6911 26.137 13.2269C26.137 6.0368 20.287 0.154297 13.137 0.154297C5.98696 0.154297 0.136963 6.0368 0.136963 13.2269Z"
      />
    </svg>
  );
};

export default FacebookIcon;
