import styled, { css } from "styled-components";
import { mediaDown } from "../../styles/Mixins";
import ThemeStyle from "../../styles/ThemeStyle";
import { Container } from "../../styles/BasicStyles";

export const Social = styled.section`
  width: 100%;
  background: ${ThemeStyle.colors.background.white};
  padding: 5rem 0;
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding: 5rem 0 3rem;
    `
  )};
`;

export const Content = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 5rem;
  border-top: 1px solid ${ThemeStyle.colors.background.darkGrey};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      padding-top: 3rem;
      justify-content: center;
    `
  )};
`;

export const Subtitle = styled.h3`
  font-size: 1.8rem;
  color: ${ThemeStyle.colors.text.grey};
  font-weight: ${ThemeStyle.fontWeight.light};
  ${mediaDown(
    ThemeStyle.breakpoints.sm,
    css`
      display: none;
    `
  )};
`;

export const IconBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem;
`;

export const Icon = styled.a`
  svg {
    fill: #645f5f;
    width: 27px;
    height: 27px;
    transition: ${ThemeStyle.transition};
    transition-property: fill;
  }

  &:hover {
    svg {
      fill: #423e3e;
    }
  }
`;
