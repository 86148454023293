import React, { FunctionComponent, useRef } from "react";
import { useFormContext } from "react-hook-form";
import Re from "react-google-recaptcha";

type Props = {
  executionHash: undefined | string | number;
  onSuccess: (value: string) => void;
};

const Recaptcha: FunctionComponent<Props> = (props) => {
  const { register, setValue } = useFormContext();
  const ref = useRef<{ execute: () => void }>();

  React.useEffect(() => {
    if (!props.executionHash) {
      return;
    }
    ref.current?.execute();
  }, [props.executionHash, ref]);

  const onChange = React.useCallback((value: string) => {
    setValue("recaptcha", value);
    props.onSuccess(value);
  }, []);

  return (
    <>
      <input type={"hidden"} {...register("recaptcha", { required: true })} />
      {/*@ts-ignore*/}
      <Re
        ref={ref}
        sitekey={process.env.GATSBY_RECAPTCHA}
        onChange={onChange}
        size={"invisible"}
      />
    </>
  );
};

export default Recaptcha;
