import * as React from "react";
import Social from "../sections/Social/Social";
import MainLayout from "../layouts/MainLayout/MainLayout";
import FormSection from "../sections/FormSection/FormSection";

const IndexPage = () => (
  <MainLayout>
    <FormSection />
    <Social />
  </MainLayout>
);

export default IndexPage;
